/* eslint-disable import/no-default-export */
import * as React from 'react'

import { Layout } from '../components/Layout/Layout'
import {
  CVSection,
  PublicationDescription,
  SoloDescription,
  SubHeader,
  EntryYear,
  AboutSection,
} from '../components/elements/about'
import { ExternalYellow } from '../components/elements/links/ExternalYellow'

const keywords = 'carina martins, biography, exhibitions, publications, cv'
const description =
  'biography, publications, exhibitions and cv of carina martins'

const AboutPage = ({ location }: { location: Location }): JSX.Element => (
  <Layout
    title={'About'}
    lang="en"
    alternate="/pt/sobre"
    description={description}
    keywords={keywords}
    location={location}
  >
    <AboutSection>
      <p>Carina Martins lives and works in Lisbon.</p>
      <p>
        She graduated in English-German Translation at Universidade Católica de
        Viseu and, in 2016, she completed a course in advanced photography at
        Ar.co – Centro de Arte e Comunicação Visual.
      </p>
    </AboutSection>
    <AboutSection>
      <SubHeader>Solo Exhibitions</SubHeader>
      <ul>
        <li>
          <EntryYear>2021</EntryYear>
          <SoloDescription>
            We wander in circles through the night and are consumed by fire,{' '}
            <a
              href="http://www.cm-tvedras.pt/agenda/detalhes/102828/"
              target="_blank"
              rel="noreferrer"
            >
              Paços - Galeria Municipal
            </a>
            , Torres Vedras
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2020</EntryYear>
          <SoloDescription>
            Physis,{' '}
            <a
              href="https://www.facebook.com/venhaanosaboamorte/"
              target="_blank"
              rel="noreferrer"
            >
              Venha a nós a Boa Morte
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2019</EntryYear>
          <SoloDescription>
            Physis,{' '}
            <a
              href="http://diferencagaleria.blogspot.com/"
              target="_blank"
              rel="noreferrer"
            >
              Galeria Diferença
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2018</EntryYear>
          <SoloDescription>
            Urupë,{' '}
            <a
              href="https://www.ccctv.org/exposicoes"
              target="_blank"
              rel="noreferrer"
            >
              Câmara Escura, CCC
            </a>
            , Torres Vedras
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2017</EntryYear>
          <SoloDescription>
            Eikasia,{' '}
            <a
              href="http://www.centerofportugal.com/pt/mosteiro-de-santa-clara-a-velha/"
              target="_blank"
              rel="noreferrer"
            >
              Mosteiro de Santa Clara-a-Velha
            </a>
            , Coimbra
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2017</EntryYear>
          <SoloDescription>
            The ink fades in waves,{' '}
            <a
              href="https://museus.ulisboa.pt/pt-pt/a_tinta_esbate-se_em_forma_de_onda"
              target="_blank"
              rel="noreferrer"
            >
              Museu Nacional de História Natural e da Ciência
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2017</EntryYear>
          <SoloDescription>
            Dialogues in unimaginated places,{' '}
            <span className="about_links">Palácio do Gelo</span>, Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2017</EntryYear>
          <SoloDescription>
            Urupë,{' '}
            <a
              href="http://www.museunacionalgraovasco.gov.pt/"
              target="_blank"
              rel="noreferrer"
            >
              Museu Nacional Grão Vasco
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2016</EntryYear>
          <SoloDescription>
            The ink fades in waves,{' '}
            <a
              href="http://jardinsefemeros.pt/edicao-2016/eventos/a-tinta-esbate-se-em-forma-de-onda/"
              target="_blank"
              rel="noreferrer"
            >
              Wrong Time Capsule
            </a>{' '}
            - Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2015</EntryYear>
          <SoloDescription>
            Dialogues in unimaginated places,{' '}
            <a
              href="http://www.voarte.com/pt/festvoarte/inshadow/edicao/evento/7734"
              target="_blank"
              rel="noreferrer"
            >
              Festival Inshadow - Espaço Santa Catarina
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2015</EntryYear>
          <SoloDescription>
            Hub-structures,{' '}
            <a
              href="http://www.museudodouro.pt/exposicoes/passado"
              target="_blank"
              rel="noreferrer"
            >
              Museu do Douro
            </a>
            , Peso da Régua
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            Hub-structures,{' '}
            <a
              href="https://arte-coa.pt/museu/"
              target="_blank"
              rel="noreferrer"
            >
              Museu do Côa
            </a>
            , Vila Nova de Foz Côa
          </SoloDescription>
        </li>

        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            Hub-structures,{' '}
            <a
              href="http://www.jardinsefemeros.pt/2014/post-artes-hub.html"
              target="_blank"
              rel="noreferrer"
            >
              Museu da Misericórdia - Jardins Efémeros
            </a>{' '}
            Viseu
          </SoloDescription>
        </li>

        <li>
          <EntryYear>2013</EntryYear>
          <SoloDescription>
            Dialogues in unimaginated places,{' '}
            <a
              href="https://www.teatroviriato.com/pt/calendario/dialogos-em-lugares-nao-imaginados/"
              target="_blank"
              rel="noreferrer"
            >
              Teatro Viriato
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2013</EntryYear>
          <SoloDescription>
            &#123;a&#125;synchro,{' '}
            <a
              href="http://jardinsefemeros.pt/"
              target="_blank"
              rel="noreferrer"
            >
              Jardins Efémeros
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2013</EntryYear>
          <SoloDescription>
            Anima Lusitani,{' '}
            <a
              href="http://jardinsefemeros.pt/"
              target="_blank"
              rel="noreferrer"
            >
              Jardins Efémeros
            </a>
            , Viseu
          </SoloDescription>
        </li>
      </ul>
    </AboutSection>
    <AboutSection>
      <SubHeader>Group Exhibitions</SubHeader>
      <ul>
        <li>
          <EntryYear>2020</EntryYear>
          <SoloDescription>
            Lisbon Ecovideo Festival,{' '}
            <a
              href="https://www.facebook.com/arquivo.mun.lisboa/videos/estufa-fria-recebe-a-partir-de-hoje-o-primeiro-festival-ecov%C3%ADdeo-de-lisboa/363927117974907/"
              target="_blank"
              rel="noreferrer"
            >
              Arquivo Municipal - Estufa fria
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2020</EntryYear>
          <SoloDescription>
            Aesthetica Art Prize Exhibition,{' '}
            <a
              href="https://www.yorkartgallery.org.uk/"
              target="_blank"
              rel="noreferrer"
            >
              York Art Gallery
            </a>
            , England
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2019</EntryYear>
          <SoloDescription>
            Reflected times – Time reflections,{' '}
            <a
              href="https://www.facebook.com/otocostudio/"
              target="_blank"
              rel="noreferrer"
            >
              Galeria Otoco
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2018</EntryYear>
          <SoloDescription>
            Bye Bye,{' '}
            <a
              href="https://5dcreativehub.negocio.site/"
              target="_blank"
              rel="noreferrer"
            >
              5D Creative Hub
            </a>
            , Lisbon
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2018</EntryYear>
          <SoloDescription>
            Solos &amp; Solidão,{' '}
            <a href="http://carmo81.pt/" target="_blank" rel="noreferrer">
              A Formosa
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2018</EntryYear>
          <SoloDescription>One Less Too Many, PASS7 , Lisbon</SoloDescription>
        </li>
        {/* <li>
          <EntryYear>2017</EntryYear>
          <SoloDescription>
            Ar.Co Bolseiros &amp; Finalistas &apos; 16 + 17,{' '}
            <a href="https://arco.pt/site/pt" target="_blank" rel="noreferrer">
              Ar.co Xabregas
            </a>
            , Lisbon
          </SoloDescription>
        </li> */}

        {/* <li>
          <EntryYear>2015</EntryYear>
          <SoloDescription>
            Exposição de Outono,{' '}
            <a href="https://arco.pt/site/pt" target="_blank" rel="noreferrer">
              Ar.co - Centro de Arte e Comunicação Visual
            </a>
            , Lisbon
          </SoloDescription>
        </li> */}
        {/* <li>
          <EntryYear>2015</EntryYear>
          <SoloDescription>
            Negativo é o Positivo,{' '}
            <a href="https://arco.pt/site/pt" target="_blank" rel="noreferrer">
              Ar.co - Centro de Arte e Comunicação Visual
            </a>
            , Lisbon
          </SoloDescription>
        </li> */}
        <li>
          <EntryYear>2015</EntryYear>
          <SoloDescription>
            E[scapes]S #1,{' '}
            <a
              href="http://jardinsefemeros.pt/edicao-2015/eventos/escapes-1/"
              target="_blank"
              rel="noreferrer"
            >
              Jardins Efémeros
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            Viagem no Tempo,{' '}
            <a href="https://bf.cm-vfxira.pt/" target="_blank" rel="noreferrer">
              Bienal de Fotografia
            </a>
            , V. Franca de Xira
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            Herbarium#01,{' '}
            <a
              href="https://www.uc.pt/jardimbotanico"
              target="_blank"
              rel="noreferrer"
            >
              Jardim Botânico
            </a>
            , Coimbra{' '}
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            <a
              href="https://4dcidadeinvisiveljardinsefemeros.wordpress.com/2014/10/27/fotografias-seleccao-dos-participantes-no-projecto/"
              target="_blank"
              rel="noreferrer"
            >
              4D a Cidade Invisível
            </a>
            , Jardins Efémeros, Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            ..... de Vista, Fábrica Só Sabão, Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2014</EntryYear>
          <SoloDescription>
            Coreografia para um Rossio,{' '}
            <a
              href="https://www.teatroviriato.com/pt/calendario/coreografia-para-um-rossio/"
              target="_blank"
              rel="noreferrer"
            >
              Teatro Viriato
            </a>
            , Viseu
          </SoloDescription>
        </li>
        <li>
          <EntryYear>2009</EntryYear>
          <SoloDescription>
            Velocidade e Movimento,{' '}
            <a href="https://tagv.pt/" target="_blank" rel="noreferrer">
              Teatro Académico Gil Vicente
            </a>
            , Coimbra
          </SoloDescription>
        </li>
        {/* <li>
          <EntryYear>2008</EntryYear>
          <SoloDescription>
            De Corpo e Alma,{' '}
            <a
              href="http://www.bienaldoportosanto.jokerartgallery.com/"
              target="_blank"
              rel="noreferrer"
            >
              III Bienal de Porto Santo
            </a>
            , Convento de Cristo, Tomar, Acert, Tondela
          </SoloDescription>
        </li> */}
        <li>
          <EntryYear>2008</EntryYear>
          <SoloDescription>
            De Corpo e Alma,{' '}
            <a
              href="http://www.bienaldoportosanto.jokerartgallery.com/"
              target="_blank"
              rel="noreferrer"
            >
              III Bienal de Porto Santo
            </a>
          </SoloDescription>
        </li>

        <li>
          <EntryYear>2008</EntryYear>
          <SoloDescription>
            De Corpo e Alma,{' '}
            <a
              href="https://www.acert.pt/acert/"
              target="_blank"
              rel="noreferrer"
            >
              Acert
            </a>
            , Tondela
          </SoloDescription>
        </li>
      </ul>
    </AboutSection>
    <AboutSection>
      <SubHeader>Publications</SubHeader>
      <ul>
        <li>
          <PublicationDescription>
            <a
              href="https://aestheticamagazine.com/"
              target="_blank"
              rel="noreferrer"
            >
              Aesthetica Magazine
            </a>
            , England
          </PublicationDescription>
        </li>
        <li>
          <PublicationDescription>
            <a
              href="https://umbigomagazine.com/en"
              target="_blank"
              rel="noreferrer"
            >
              Umbigo Magazine (online edition)
            </a>
            , Lisbon
          </PublicationDescription>
        </li>
        <li>
          <PublicationDescription>
            <a href="https://revistabica.com/" target="_blank" rel="noreferrer">
              Revista Bica
            </a>
            , Viseu
          </PublicationDescription>
        </li>
        <li>
          <PublicationDescription>
            <a
              href="http://girasolazul.com/girazine/"
              target="_blank"
              rel="noreferrer"
            >
              Girazine
            </a>
            , Viseu
          </PublicationDescription>
        </li>
        <li>
          <PublicationDescription>
            <a
              href="https://jezrileyfrench.co.uk/verdure-engraved.php"
              target="_blank"
              rel="noreferrer"
            >
              Tristesse Engraved/Verdure Engraved
            </a>
            , England
          </PublicationDescription>
        </li>
      </ul>
    </AboutSection>
    <CVSection>
      <ExternalYellow linkTo="en/CV_Carina_Martins_EN.pdf">
        DOWNLOAD CV
      </ExternalYellow>
    </CVSection>
  </Layout>
)

export default AboutPage
